import React, { useEffect, useState } from "react";
import { IoWarningOutline } from "@react-icons/all-files/io5/IoWarningOutline";
import ReactRouterPrompt from "react-router-prompt";

import { Button, Text, Box, Modal, ModalActions, ModalBody, ModalCloseBtn, ModalHeader, BaseBoxProps } from "Atoms";
import { useLanguage } from "LanguageProvider";
import { useEventListener } from "CoreHooks";

type ButtonThemeAndText = {
    text: string;
    theme: string;
};

type Props = {
    message: string;
    confirmButton?: ButtonThemeAndText;
    cancelButton?: ButtonThemeAndText;
    when?: boolean;
    unloadPrompt?: boolean;
    modalStyle?: BaseBoxProps;
    placement?: "top" | "center" | "bottom";
};

/**
 * Custom component prompt when changing routes replaces standard browser prompt
 * @description  can be done conditionally with flag or remove when 'flag' and will
 * prompt when leaving current page (clicking away from routes). Also an additonal flag for unload
 * this is a separate prompt in the browser (not customizable)
 * @param {string} message - prompt message to display when changing routes
 * @param {ButtonThemeAndText} confirmButton - optional can change text and theme
 * @param {ButtonThemeAndText} cancelButton - optional can change text and theme
 * @param {boolean} when - flag when e.g something hasn't been saved  / default will be true
 * @param {boolean} unloadPrompt - flag for refresh and tab close prompt / defauly will be false
 */
export const RoutePrompt: React.FC<Props> = ({
    message,
    confirmButton,
    cancelButton,
    when = true,
    unloadPrompt = false,
    modalStyle,
    placement = "top"
}) => {
    const { translate } = useLanguage();
    const [whenCondition, setWhenCondition] = useState<boolean>(when);

    useEffect(() => {
        setWhenCondition(when);
    }, [when]);

    useEventListener(window, "beforeunload", (event: BeforeUnloadEvent) => { 
        if (whenCondition && unloadPrompt) {
            event.preventDefault();
            return message;
        }
    });

    return (
        <>
            <ReactRouterPrompt when={whenCondition}>
                {({ isActive, onCancel, onConfirm }) => (
                    <Modal open={isActive} size="xs" placement={placement} borderRadius="xl" {...modalStyle}>
                        <ModalCloseBtn onClick={onCancel} />
                        <ModalHeader display="flex" justifyContent="center">
                            <Box as={IoWarningOutline} size="14" color="orange.500" />
                        </ModalHeader>
                        <ModalBody display="flex" justifyContent="center">
                            <Text fontSize="1.5rem" fontWeight="bold" color="gray.800" textAlign={"center"}>
                                {message}
                            </Text>
                        </ModalBody>
                        <ModalActions display="flex" justifyContent="center">
                            <Button
                                size="lg"
                                themeColor={confirmButton?.theme ? confirmButton?.theme : "red"}
                                onClick={onConfirm}
                                borderRadius={"xl"}
                                variant="outline"
                                minW={"100px"}
                            >
                                {confirmButton?.text ? confirmButton.text : translate("yes")}
                            </Button>
                            <Button
                                size="lg"
                                themeColor={cancelButton?.theme ? cancelButton?.theme : "green"}
                                ml={4}
                                onClick={onCancel}
                                borderRadius={"xl"}
                                variant="outline"
                                minW={"100px"}
                            >
                                {cancelButton?.text ? cancelButton.text : translate("no")}
                            </Button>
                        </ModalActions>
                    </Modal>
                )}
            </ReactRouterPrompt>
        </>
    );
};
